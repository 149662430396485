import * as React from 'react'
import CriteriaIcon from '../utils/criteriaIcon'

const MethodItem = ({title, txt, pos, icon, link}) => {
  return (
    <>
      <div className={`method__item method__item-${pos}`}>
        {CriteriaIcon(icon, 'mb-4 icon-old-pink')}
        <h3 className="emphasis">{title}</h3>
        <p className="paragraph-bold mt-1">{txt}</p>
      </div>
    </>
  )
}

export default MethodItem