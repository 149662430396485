import React, {useState, useEffect}  from 'react'
import Button from './button'

import texts2022 from '../json/intro.json'
import texts2021 from '../json/2021/intro.json'

const PresentationSection = ({year}) => {
  const [texts, setTexts] = useState(year === '2021' ? texts2021 : texts2022)

  useEffect(() => {
    setTexts(year === '2021' ? texts2021 : texts2022)
  }, [year])

  return (
    <>
      { texts &&
        <div className="fluid-grid">
          <h2 className="title-section">{texts.title}</h2>

          <div className="row mt-6 md:mt-9">
            <div className="lg-col-1">
              <div className="paragraph" dangerouslySetInnerHTML={{ __html: texts.text }}></div>
            </div>
            <div className="lg-col-2">
              <blockquote className="emphasis mt-6 md:mt-22">{texts.emphasis}</blockquote>

              {texts.mentions &&
                <p className="mt-4">{texts.mentions}</p>
              }

              {texts.text2 &&
                <div className="paragraph mt-12" dangerouslySetInnerHTML={{ __html: texts.text2 }}></div>
              }

              <Button link={`${year !== '2022' ? `/${year}` : ''}/notre-demarche`} label="En savoir plus sur la démarche" className="button mt-12 md:mt-8" />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default PresentationSection
