import * as React from 'react'
import Slider from 'react-slick'
import { useMediaQuery } from 'react-responsive'

import MethodItem from './methodItem'
import Button from './button'

const MethodSection = ({year}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  return (
    <div className="fluid-grid">
      <h2 className="title-section mb-4 md:mb-10">Une méthodologie<br/> en 4 objectifs</h2>

      <div className="row">
        <div className="lg-col-1">
          <p className="paragraph">L’impact positif digital des marques peut s’évaluer de différentes façons. Cet observatoire choisit de se concentrer sur les moyens d’expression propriétaires des marques, à savoir leurs sites internet, évalués selon 45 indicateurs factuels mesurables, regroupés en 4 grands objectifs, pour donner un score final sur 100&nbsp;:</p>
        </div>
        
        <div className="lg-col-2">
          { !isMobile && 
            <div className="row method">
              <MethodItem
                title="L'utilisabilité"
                txt="Évaluer l’efficacité des parcours utilisateur et leur adéquation avec leurs besoins."
                pos="left"
                icon="usability"
                link="/utilisabilite" />
              <MethodItem
                title="L’écoconception"
                txt="Estimer l’empreinte environnementale de chaque site."
                pos="right"
                icon="conception"
                link="/ecoconception" />
              <MethodItem
                title="L’accessibilité"
                txt="Mesurer la capacité des sites à s’adapter à des publics en situation de handicap."
                pos="left"
                icon="accessibility"
                link="/accessibilite" />
              <MethodItem
                title="L’efficacité SEO"
                txt="Quantifier la performance des contenus des marques."
                pos="right"
                icon="seo"
                link="/efficacite-seo" />
            </div>
          }
        </div>
      </div>
      
      <div>
        { isMobile &&
          <div className="method-wrap mt-12 md:mt-0">
            <Slider dots={false} infinite={true} slidesToScroll={1} slidesToShow={1}>
              <MethodItem
                title="L'utilisabilité"
                link="/utilisabilite"
                txt="Évaluer l’efficacité des parcours utilisateur et leur adéquation avec leurs besoins."
                pos="left"
                icon="usability" />
              <MethodItem
                title="L’écoconception"
                link="/ecoconception"
                txt="Estimer l’empreinte environnementale de chaque site."
                pos="right"
                icon="conception" />
              <MethodItem
                title="L’accessibilité"
                link="/accessibilite"
                txt="Mesurer la capacité des sites à s’adapter à des publics en situation de handicap."
                pos="left"
                icon="accessibility" />
              <MethodItem
                title="L’efficacité SEO"
                link="/efficacite-seo"
                txt="Quantifier la performance des contenus des marques."
                pos="right"
                icon="seo" />
            </Slider>
          </div>
        }
      </div>

      <div className="row">
        <div className="lg-col-2 lg-col-offset-1">
          <Button link={`${year !== '2022' ? `/${year}` : ''}/notre-methodologie`} label="Notre méthodologie en détail" className="button mt-12 md:mt-10" />
        </div>
      </div>
    </div>
  )
}

export default MethodSection
