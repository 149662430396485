import React, {useState} from 'react'
import {useEventListener} from 'react-recipes'
import Share from './share'

import BarometerIcon from '../icons/barometer.svg'
import ArrowIcon from '../icons/arrow.svg'

const Anchors = ({activeLink, presOffset, podiumOffset, methodOffset, contactOffset, aboutOffset, year}) => {
  const [isFixed, setIsFixed] = useState(false)
  const [dropdownActive, setDropdownActive] = useState(false)
  const anchorsWrapper = React.createRef()

  const label = {
    'pres': 'Présentation',
    'method': 'Méthodologie',
    'podium': `Palmarès ${year}`,
    'about': 'À propos',
    'contact': 'Contact'
  }

  const handleScroll = () => {
    if(anchorsWrapper.current) {
      if(window.pageYOffset >= anchorsWrapper.current.offsetTop + 54) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
    }
  }

  const goTo = (offset) => {
    window.scroll({
      top: offset, 
      left: 0, 
      behavior: 'smooth'
    })

    setDropdownActive(false)
  }

  useEventListener('scroll', handleScroll)

  return (
    <div className="anchors-wrap" ref={anchorsWrapper}>
      <nav className={`anchors ${isFixed ? 'is-fixed' : ''}`} aria-label="Navigation principale">
        <button className="anchors__side anchors__back" onClick={() => goTo(-40)}>
          <BarometerIcon className="icon-old-pink" aria-hidden="true" />
          <span className="sr-only">Retour en haut de page</span>
        </button>
        <button onClick={() => { setDropdownActive(!dropdownActive)}} className={`anchors__nav-link anchors__mobile is-active ${dropdownActive ? 'is-open' : ''}`} aria-hidden="true">
          {label[activeLink]}
          <ArrowIcon />
        </button>
        <ul className={`anchors__nav ${dropdownActive ? 'is-active' : ''}`}>
          <li className="anchors__nav-item">
            <button onClick={() => goTo(window.innerWidth >= 768 ? presOffset : presOffset - 40)} className={`anchors__nav-link ${activeLink === 'pres' ? 'is-active' : ''}`}>Présentation</button>
          </li>
          <li className="anchors__nav-item">
            <button onClick={() => goTo(methodOffset)} className={`anchors__nav-link ${activeLink === 'method' ? 'is-active' : ''}`}>Méthodologie</button>
          </li>
          <li className="anchors__nav-item">
            <button onClick={() => goTo(podiumOffset)} className={`anchors__nav-link ${activeLink === 'podium' ? 'is-active' : ''}`}>Palmarès <span className="md:hidden lg:inline">{year}</span></button>
          </li>
          <li className="anchors__nav-item">
            <button onClick={() => goTo(aboutOffset)} className={`anchors__nav-link ${activeLink === 'about' ? 'is-active' : ''}`}>À propos</button>
          </li>
          <li className="anchors__nav-item">
            <button onClick={() => goTo(contactOffset)} className={`anchors__nav-link ${activeLink === 'contact' ? 'is-active' : ''}`}>Contact</button>
          </li>
        </ul>
        <Share yera={year} />
      </nav>
    </div>
  )
}

export default Anchors