import React, {useState, useLayoutEffect, useCallback} from 'react'
import {useEventListener} from 'react-recipes'

import Anchors from './anchors'
import PresentationSection from './presentationSection'
import MethodSection from './methodSection'
import RankingSection from './rankingSection'
import AboutSection from './aboutSection'
import ContactSection from './contactSection'

const AnchorsWrapper = ({year = '2022'}) => {
  const [activeLink, setActiveLink] = useState('pres')
  
  const pres = React.createRef()
  const method = React.createRef()
  const podium = React.createRef()
  const about = React.createRef()
  const contact = React.createRef()

  const [presOffset, setPresOffset] = useState(0)
  const [methodOffset, setMethodOffset] = useState(0)
  const [podiumOffset, setPodiumOffset] = useState(0)
  const [aboutOffset, setAboutOffset] = useState(0)
  const [contactOffset, setContactOffset] = useState(0)

  const handleCalcs = useCallback(() => {
    if (pres.current) { setPresOffset(pres.current.offsetTop - (window.innerWidth < 768 ? 40 : 20)) }
    if (method.current) { setMethodOffset(method.current.offsetTop - (window.innerWidth < 768 ? 40 : 20)) }
    if (podium.current) { setPodiumOffset(podium.current.offsetTop - (window.innerWidth < 768 ? 40 : 20)) }
    if (about.current) { setAboutOffset(about.current.offsetTop - (window.innerWidth < 768 ? 40 : 20)) }
    if (contact.current) { setContactOffset(contact.current.offsetTop - (window.innerWidth < 768 ? 40 : 20)) }
  }, [pres,method, podium, about, contact ])

  const handleScroll = () => {
    const scroll = window.pageYOffset
    
    if (scroll >= contactOffset) {
      setActiveLink('contact')
    } else if (scroll >= aboutOffset) {
      setActiveLink('about')
    } else if (scroll >= podiumOffset) {
      setActiveLink('podium')
    } else if (scroll >= methodOffset) {
      setActiveLink('method')
    } else {
      setActiveLink('pres')
    }
  }

  useLayoutEffect(() => {
    handleCalcs()
  }, [handleCalcs])

  useEventListener('scroll', handleScroll)
  useEventListener('resize', handleCalcs)

  return (
    <>
      <Anchors
        activeLink={activeLink}
        presOffset={presOffset}
        aboutOffset={aboutOffset}
        contactOffset={contactOffset}
        podiumOffset={podiumOffset}
        methodOffset={methodOffset}
        year={year} />

      <div>
        <section className="section section-presentation" ref={pres}>
          <PresentationSection year={year} />
        </section>

        <section className="section" ref={method}>
          <MethodSection year={year} />
        </section>

        <section className="section" ref={podium}>
          <RankingSection year={year} />
        </section>

        <section className="section section--about" ref={about}>
          <AboutSection />
        </section>

        <section ref={contact}>
          <ContactSection />
        </section>
      </div>
    </>
  )
}

export default AnchorsWrapper