import * as React from 'react'

const BrandCard = ({brand, criteria, number, isEx}) => {
  const {cat, global, usability, logo, accessibility, seo, conception } = brand[1]
  
  return (
    <div className={`brand-card ${brand[1]['evol_global'] ? '' : 'no-arrow'} bg-${cat}`}>
      { number &&
        <div className={`brand-card__number ${number === 1 ? 'brand-card__number--1' : ''} knockout-title`}>{number} {isEx && <span>ex</span>}</div>
      }
      <div className="brand-card__inner flex-col justify-between">
        <div className="flex-grow flex items-center justify-center">
          <img className="brand-card__logo" src={`/images/logos/${logo}`} alt={brand[0]} />
        </div>
        <div className="brand-card__notes flex">
          <div className={`brand-card__global-note ${brand[1]['evol_global'] ? `brand-card__global-note--${brand[1]['evol_global']}` : ''} ${criteria === 'global' ? 'is-active' : ''}`}>
            <p className="label mb-6 md:mb-7 lg:mb-9">Impact positif global</p>
            <p className="number-big">{global}</p>
          </div>

          <div className="flex-grow">
            <div className={`brand-card__note ${brand[1]['evol_usability'] ? `brand-card__note--${brand[1]['evol_usability']}` : ''} ${criteria === 'usability' ? 'is-active' : ''}`}>
              <p className="label">Utilisabilité</p>
              <p className="number">{usability}</p>
            </div>
            <div className={`brand-card__note ${brand[1]['evol_conception'] ? `brand-card__note--${brand[1]['evol_conception']}` : ''} ${criteria === 'conception' ? 'is-active' : ''}`}>
              <p className="label">Écoconception</p>
              <p className="number">{conception}</p>
            </div>
            <div className={`brand-card__note ${brand[1]['evol_accessibility'] ? `brand-card__note--${brand[1]['evol_accessibility']}` : ''} ${criteria === 'accessibility' ? 'is-active' : ''}`}>
              <p className="label">Accessibilité</p>
              <p className="number">{accessibility}</p>
            </div>
            <div className={`brand-card__note ${brand[1]['evol_seo'] ? `brand-card__note--${brand[1]['evol_seo']}` : ''} ${criteria === 'seo' ? 'is-active' : ''}`}>
              <p className="label">Efficacité SEO</p>
              <p className="number">{seo}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandCard