import React from 'react'

import Button from './button'

const AboutSection = () => {
  return (
    <div className="fluid-grid about">
      <div className="row">
        <div className="lg-col-2">
          <p className="emphasis">
            L'Observatoire de l’impact positif est une initiative de Big Youth, agence de design digital, avec le soutien d’Occurrence, cabinet d'études et conseil en communication.
          </p>
        </div>
      </div>

      <div className="row about__items">
        <div className="xl-col-1 about__item">
          <img width="234" height="73" className="mb-6 md:mb-10" src="/images/logo-BY.svg" alt=" Big Youth" />

          <p className="paragraph w-full">
            Big Youth est l’agence digitale du groupe Makheia. Sa signature « Positive Impact by Design » reflète une vision : celle qui consiste à défendre une autre façon de travailler le digital, avec l’idée que nos actions peuvent avoir un impact positif sur les clients, leurs propres clients, l’environnement, et l’agence elle-même.
          </p>

          <Button link="https://www.bigyouth.fr/" external={true} label="En savoir plus sur l'agence" className="button mt-10" />
        </div>

        <div className="xl-col-1 about__item">
          <img width="238" height="26" className="mb-6 md:mb-10" src="/images/logo-occurence.svg" alt=" Occurrence" />

          <p className="paragraph w-full">
            Occurrence est un cabinet d’études et de conseil  indépendant, proposant aux Directions de la communication de grandes entreprises et d’institutions une gamme de solutions permettant d’éclairer leurs décisions stratégiques, de mesurer l’efficacité de leurs actions et d’optimiser le management de la communication des processus et du pilotage au sein de leur organisation.
          </p>

          <Button link="http://occurrence.fr/" external={true} label="En savoir plus sur le cabinet" className="button mt-10" />
        </div>
      </div>
    </div>
  )
}

export default AboutSection