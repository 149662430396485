import React, {useState} from 'react'
import {Link} from 'gatsby'
import { useForm } from 'react-hook-form'

import Button from './button'

const WhitePaper = ({year = '2022'}) => {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur'
  })
  const [formState, setFormState] = useState('initial')

  const onSubmit = (data, e) => { 
    e.preventDefault()
    let formData = new FormData(document.getElementById('whitePaper'))

    fetch(`${process.env.GATSBY_API_URL}/api/white-book`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => setFormState('success'))
      .catch(error => setFormState('error'))
	}

  return (
    <>
      { formState !== 'success' &&
        <>
          <form className="form -mt-8" noValidate id="whitePaper" name="whitePaper" method="POST" onSubmit={handleSubmit(onSubmit)}>
            <p className="italic text-right mb-6 mt-8 xl:mt-0">* Champs obligatoires</p>

            <div className="form__item">
              <label htmlFor="nameWhitePaper" className="block form__label">Nom<sup>*</sup></label>
              <input 
                type="text"
                name="white_book[lastName]"
                className={errors['white_book'] && errors['white_book'].lastName ? 'has-error' : ''}
                id="nameWhitePaper"
                ref={register({ required: true })}  />
              
              {errors['white_book'] && errors['white_book'].lastName && <span className="form__error">Ce champ est obligatoire</span>}
            </div>

            <div className="form__item">
              <label htmlFor="firstnameWhitePaper" className="block form__label">Prénom<sup>*</sup></label>
              <input 
                type="text"
                name="white_book[firstName]"
                className={errors['white_book'] && errors['white_book'].firstName ? 'has-error' : ''}
                id="firstnameWhitePaper"
                ref={register({ required: true })}  />
              
              {errors['white_book'] && errors['white_book'].firstName && <span className="form__error">Ce champ est obligatoire</span>}
            </div>

            <div className="form__item">
              <label htmlFor="mailWhitePaper" className="block form__label">Email<sup>*</sup></label>
              <input 
                type="email"
                name="white_book[email]"
                className={errors['white_book'] && errors['white_book'].email ? 'has-error' : ''}
                id="mailWhitePaper"
                ref={register({ 
                  required: {
                    value: true,
                    message: 'Ce champ est obligatoire'
                  }, 
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Le format de l\'email est invalide'
                  }
                })}  />
              
              {errors['white_book'] && errors['white_book'].email && <span className="form__error">{errors['white_book'] && errors['white_book'].email.message}</span>}
            </div>

            {/* checkbox */}
            <div className="form__item">
              <label className="custom-checkbox">
                <input name="rgpd" type="checkbox" ref={register({ required: true })}/>
                <span className="custom-checkbox__label">J'accepte que la direction de la communication de Big Youth traite les données recueillies dans le cadre de ma demande. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, <Link to="/vos-donnees">cliquez-ici</Link>.</span>
              </label>
              {errors.rgpd && <span className="form__error">Ce champ est obligatoire</span>}
            </div>

            { formState === 'error' &&
              <p className="paragraph form__global-error text-right mb-4">Oups, une erreur est survenue.</p>
            }
            
            <div className="flex justify-end mt-6">
              <button type="submit" className="button button--white">Envoyer</button>
            </div>
          </form>
        </>
      }

      { formState === 'success' &&
        <>
          {year === '2021'
            ? <Button link="/pdf/Livre-blanc_Observatoire-impact-positif.pdf" download={true} external={true} label="Télécharger la version PDF" className="button button--white self-center" />
            : <Button link="/pdf/Livre-blanc_Observatoire-impact-positif-2022.pdf" download={true} external={true} label="Télécharger la version PDF" className="button button--white self-center" />
          } 
          </>
      }
    </>
  )
}

export default WhitePaper
