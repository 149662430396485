import React, {useEffect, useState, useRef, useLayoutEffect} from 'react'
import Slider from 'react-slick'
import {useEventListener} from 'react-recipes'

import CustomSelect from './customSelect'
import BrandCard from './brandCard'
import RankingContent from './rankingContent'
import WhitePaper from './whitePaper'
import Button from './button'

import orderBrands from '../utils/orderBrands'
import getOffsetEl from '../utils/getOffsetEl'

import criterias from '../json/criterias.json'
import sectors from '../json/sectors.json'
import brands from '../json/brands.json'
import brands2021 from '../json/2021/brands.json'

import BarometerIcon from '../icons/barometer.svg'

const RankingSection = ({year}) => {
  const [orderedBrands, setOrderedBrands] = useState(Object.entries(year === '2021' ? brands2021 : brands))
  const [currentCriteria, setCurrentCriteria] = useState('global')
  const [currentSector, setCurrentSector] = useState('all')
  const [globalAverage, setGlobalAverage] = useState(67)
  const [sectorAverage, setSectorAverage] = useState(0)
  const [stickyButton, setStickyButton] = useState(false)
  const [stickyOffset, setStickyOffset] = useState(0)
  const [stickyHeight, setStickyHeight] = useState(0)
  const [filtersOffset, setFiltersOffset] = useState(0)
  const [analysisVisible, setAnalysisVisible] = useState(true)

  const slick = useRef(null)
  const rankingAnchor = useRef(null)

  const [isMobile, setIsMobile] = useState(false)

  // build options for custom selects (criteria + sector)
  let optionsCriteria = []
  let optionsSector = []
  let currentScore = useRef(0)

  Object.entries(criterias).forEach(criteria => {
    optionsCriteria.push({ 'value': criteria[0], 'label': criteria[1] })
  })

  Object.entries(sectors).forEach(sector => {
    optionsSector.push({ 'value': sector[0], 'label': sector[1] })
  })

  // set current criteria or sector on select change 
  const changeSelect = (type, value) => {
    currentScore.current = 0

    if(type === 'criteria') {
      setCurrentCriteria(value)
      const {orderedBrands, globalAverage, sectorAverage} = orderBrands(value, currentSector, year)
      setOrderedBrands(orderedBrands)
      setGlobalAverage(globalAverage)
      setSectorAverage(sectorAverage)

      window.dataLayer.push({
        'event': 'filter_hp',
        'criterias': `${criterias[value]} - ${sectors[currentSector]}`
      })
    } else {
      setCurrentSector(value)
      const {orderedBrands, globalAverage, sectorAverage} = orderBrands(currentCriteria, value, year)
      setOrderedBrands(orderedBrands)
      setGlobalAverage(globalAverage)
      setSectorAverage(sectorAverage)

      window.dataLayer.push({
        'event': 'filter_hp',
        'criterias': `${criterias[currentCriteria]} - ${sectors[value]}`
      })
    }

    if(slick.current !== null) {
      slick.current.slickGoTo(0)
    }

    handleCalcs()

    if (year === '2022') {
      let criteria = type === 'criteria' ? value : currentCriteria
      let sector = type === 'sector' ? value : currentSector

      if (criteria !== 'global' && sector !== 'all') {
        setAnalysisVisible(false)
      } else {
        setAnalysisVisible(true)
      }
    }
  }

  const onChangeSector = (optionSelected) => {
    changeSelect('sector', optionSelected.value)
  }

  const onChangeCriteria = (optionSelected) => {
    changeSelect('criteria', optionSelected.value)
  }

  const handleScroll = () => {
    if(stickyHeight === 0) {
      setStickyHeight(rankingAnchor.current.offsetHeight + rankingAnchor.current.nextElementSibling.offsetHeight)
    }

    if(window.pageYOffset > stickyOffset - 60 && window.pageYOffset < stickyOffset - 60 + stickyHeight - window.innerHeight) {
      setStickyButton(true)
    } else {
      setStickyButton(false)
    }
  }

  const handleResize = () => {
    handleCalcs()
  }

  const handleCalcs = (resize = true) => {
    if(rankingAnchor.current) {
      setStickyOffset(getOffsetEl(rankingAnchor.current))
      setFiltersOffset(getOffsetEl(rankingAnchor.current.parentNode.parentNode.parentNode))

      setStickyHeight(0)
    }

    setIsMobile(window.innerWidth < 1280 ? true : false)
  }

  useEffect(() => {
    setCurrentCriteria('global')
    setCurrentSector('all')
    setGlobalAverage(0)
    setSectorAverage(0)
    currentScore.current = 0

    const {orderedBrands, globalAverage, sectorAverage} = orderBrands('global', 'all', year)
    setOrderedBrands(orderedBrands)
    setGlobalAverage(globalAverage)
    setSectorAverage(sectorAverage)

    if(slick.current) {
      slick.current.innerSlider.onWindowResized()
    }
  }, [slick, year])

  useLayoutEffect(() => {
    handleCalcs(false)
  }, [])

  useEventListener('scroll', handleScroll)
  useEventListener('resize', handleResize)

  return (
    <> 
      {/*  all wrap */}
      <div className={`ranking-wrap ranking-wrap--${currentSector}`}>
        <div className="fluid-grid">
          {/* header w/ filters */}
          <div className="xl:flex justify-between pb-12 xl:pb-0">
            <h2 className="title-section">Le palmarès de<br/> l'Observatoire {year}</h2>

            { isMobile &&
              <p className="paragraph italic">
                Tous les scores sont exprimés sur 100<br/>
                { year !== '2021' &&
                  <span className="legend">
                    <span className="legend__intro">Evolution des index par rapport à 2021 :</span>
                    <span className="legend__item legend__item--up">En hausse</span>
                    <span className="legend__item legend__item--down">En baisse</span>
                    <span className="legend__item legend__item--equal">Stable</span>
                  </span>
                }
              </p>
            }

            <div className="pt-8 lg:pt-4">
              <p className="label-filter md:hidden mb-3" aria-hidden="true">Filtrer par objectif et par secteur :</p>
              <div className="custom-select-wrap">
                <label className="label-filter whitespace-nowrap" htmlFor="criteriaLabel">Filtrer par objectif :</label>
                <CustomSelect
                  id="criteriaLabel"
                  options={optionsCriteria}
                  onChange={onChangeCriteria}
                  onChangeMobile={(e) => changeSelect('criteria', e.target.value )}
                  cssClass="is-index"
                />
              </div>

              <div className="custom-select-wrap">
                <label className="label-filter" htmlFor="sectorLabel">Filtrer par secteur :</label>
                <CustomSelect
                  id="sectorLabel"
                  options={optionsSector}
                  onChange={onChangeSector}
                  cssClass={`is-sector is-${currentSector}`}
                  onChangeMobile={(e) => changeSelect('sector', e.target.value )}
                />
              </div>
            </div>

            {/* mobile additional button */}
            <button onClick={() => window.scroll({top: filtersOffset - 60, left: 0, behavior: 'smooth'})} aria-hidden="true"  className={`button button--full ranking-mobile-button ${stickyButton ? 'is-visible' : ''}`}><span className="icon"></span>Filtrer par objectif et par secteur</button>
          </div>
          { !isMobile &&
            <p className={`paragraph italic pb-12 ${year !== '2021' ? 'lg:pb-16' : 'lg:pb-20'}`}>
              Tous les scores sont exprimés sur 100<br/>
              { year !== '2021' &&
                <span className="legend">
                  <span className="legend__intro">Evolution des index par rapport à 2021 :</span>
                  <span className="legend__item legend__item--up">En hausse</span>
                  <span className="legend__item legend__item--down">En baisse</span>
                  <span className="legend__item legend__item--equal">Stable</span>
                </span>
              }
            </p>
          }

          {/* cards  */}
          <div ref={rankingAnchor} className="subsection ranking">
            {/* slider or cards */}
            { !isMobile ?
              <div className="flex justify-between">
                { orderedBrands.slice(0,3).map((brand, i) => {
                  let isEx = false
                  let isExNext = false
                  if (currentScore.current !== brand[1][currentCriteria]) {
                    currentScore.current = brand[1][currentCriteria]
                  } else {
                    isEx = true
                  }

                  if (i !== 2 && brand[1][currentCriteria] === orderedBrands[i + 1][1][currentCriteria]) {
                    isExNext = true
                  }

                  return (
                    <BrandCard key={i} brand={brand} criteria={currentCriteria} number={isEx ? i : i+1} isEx={isEx || isExNext ? true : false} />
                  )
                })}
              </div>
            :
              <Slider ref={slick} id="brand-slider" dots={false} infinite={false} slidesToScroll={1} slidesToShow={1} variableWidth={true}>
                { orderedBrands.slice(0,3).map((brand, i) => {
                  let isEx = false
                  let isExNext = false
                  if (currentScore.current !== brand[1][currentCriteria]) {
                    currentScore.current = brand[1][currentCriteria]
                  } else {
                    isEx = true
                  }

                  if (i !== 2 && brand[1][currentCriteria] === orderedBrands[i + 1][1][currentCriteria]) {
                    isExNext = true
                  }

                  return (
                    <BrandCard key={i} brand={brand} criteria={currentCriteria} number={isEx ? i : i+1} isEx={isEx || isExNext ? true : false} />
                  )
                })}
              </Slider>
            }
          </div>
          
          {/* content */}
          <div className="subsection ranking-content">
            { analysisVisible &&
              <RankingContent
                globalAverage={globalAverage}
                sectorAverage={sectorAverage}
                currentSector={currentSector}
                currentCriteria={currentCriteria}
                year={year} />
            }
          </div>
        </div>
      </div>

      {/* ranking  */}
      <div className="fluid-grid">
        <div className="subsection xl:flex justify-between items-end">
          <h3 className="knockout-title -ml-4 md:-ml-8 lg:-mt-21 lg:-mb-4">Classement</h3>
          <Button link={`${year !== '2022' ? `/${year}` : ''}/classement`} label="Consulter les résultats complets" className="button button-all-ranking" />
        </div>
      </div>
      
      {/* book section */}
      <div className="book-section full-section">
        <BarometerIcon className="book-section__deco" viewBox="0 0 24 24" aria-hidden="true" />
        <div className="fluid-grid">
          <div className="row">
            <p className="title-section lg-col-2 lg:pr-16">
              Retrouvez l’intégralité de l'observatoire dans notre livre blanc.
            </p>
            <div className="lg-col-1 flex justify-end mt-6 lg:mt-0">
              <WhitePaper year={year} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RankingSection
